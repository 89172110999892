import { createRoot } from 'react-dom';

import './style.scss';
import Style from './Components/Common/Style';
import TextTyping from './Components/Common/TextTyping';

document.addEventListener('DOMContentLoaded', () => {
	const typingEls = document.querySelectorAll('.wp-block-ttb-text-typing');
	typingEls.forEach(typingEl => {
		const attributes = JSON.parse(typingEl.dataset.attributes);

		createRoot(typingEl).render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<TextTyping attributes={attributes} />
		</>);

		typingEl?.removeAttribute('data-attributes');
	});
});