import { getBackgroundCSS, getSpaceCSS, getTypoCSS } from '../../../../Components/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { textAlign, background, padding, prefixTypo, prefixColor, typingContentsTypo, suffixTypo, suffixColor } = attributes;

	const textTypingSl = `#ttbTextTyping-${clientId} .ttbTextTyping`;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${getTypoCSS('', prefixTypo)?.googleFontLink}
		${getTypoCSS('', typingContentsTypo)?.googleFontLink}
		${getTypoCSS('', suffixTypo)?.googleFontLink}
		${getTypoCSS(`${textTypingSl} .prefixText`, prefixTypo)?.styles}
		${getTypoCSS(`${textTypingSl} .typingContents, ${textTypingSl} .typed-cursor`, typingContentsTypo)?.styles}
		${getTypoCSS(`${textTypingSl} .suffixText`, suffixTypo)?.styles}

		${textTypingSl}{
			text-align: ${textAlign};
			${getBackgroundCSS(background)}
			padding: ${getSpaceCSS(padding)};
		}
		${textTypingSl} .prefixText{
			color: ${prefixColor};
		}
		${textTypingSl} .suffixText{
			color: ${suffixColor};
		}
		`.replace(/\s+/g, ' ')
	}} />;
}
export default Style;